import React from 'react';
import Impact1 from '../assets/images/impact1.png';
import Impact2 from '../assets/images/impact2.png';
import '../index.css';
import logo from '../assets/images/logo.png';

const Home = () => {
    return (
        <>
          <div className="mx-4"> 
       
       <div className="container h-full w-full mx-auto flex flex-col bg-white">
         
         <div className="grid w-full grid-cols-1 my-auto mt-12 mb-8 md:grid-cols-2 xl:gap-14 md:gap-5">
           <div className="flex flex-col justify-center col-span-1 text-center lg:text-start">
             <h1 className="mb-8 text-4xl font-extrabold leading-tight lg:text-6xl text-dark-grey-900">THE DISABLED PEOPLE ECONOMIC EMPOWERMENT
             INITIATIVE</h1>
             

           </div>
           <div className="items-center justify-end hidden col-span-1 md:flex">
             <img className="w-4/5 rounded-md" src={logo} alt="header" />
           </div>
         </div>

         <section class="py-20 w-full bg-white">
           <div class="flex flex-col mx-auto space-y-12 max-w-7xl xl:px-12">
               <div id="about_initiative" class="mx-4">
               <h2 class="w-full text-3xl font-bold text-center sm:text-4xl md:text-5xl">About DPEEIK</h2>
               <p class="w-full py-8 ">The Disabled People Economic Empowerment Initiative-Kenya (DPEEIK) is a
social economic empowerment initiative that has been founded by MEDICAL
AND CLINICAL MEDICINE STUDENTS from JKUAT plus other medical
students across the country in partnership with WOMEN ACCESS TO RURAL
HEALTH FOUNDATION: (WARHF Kenya), and some KENYANS LIVING IN
DIAPOSRA under guidance and leadership of Dr. Joseph Kweri. The mission of
this initiative is to support and enable persons living with disabilities explore their
entrepreneurship potentials towards becoming self-reliant without being dependent
on others with a view to enabling them take care of their own lives, support their
families as well as enable them take part in promoting the attainment of a
sustainable economic growth as envisioned by His Excellency the President.
Further, this initiative has been founded as a value chain addition to the wheel
chair provision program by JKUAT where the disabled persons are being given
wheel-chairs for free to enable them ambulate on their own. This initiative hence
seeks to answer the question (“these wheeled disabled persons are then enabled to
move to go where and to do what?). As such this initiative seeks to provide
solutions to these ambulatory enabled persons to becoming economically
productive and not to continue being beggars. Currently we have about 150
disabled people who have benefited from the JKUAT wheelchairs project and
many more are coming up. This program is hence focused to bolstering the
existing county and national support programs for persons living with disabilities
(PLWD)in Kenya in trying to improving their dignity and livelihoods.
In this regard, Disabled People Economic Empowerment Initiative-Kenya
(DPEEIK) in corroboration with well-wishers and partners spanning from
individuals, groups, government agencies, private companies, government and
non-governmental institutions, and international bodies that would make this noble
course a success to put up temporary economic enterprises containers to support
disabled people to become self-reliant by changing the economic fortunes andlivelihoods.</p>
       </div>
       <div id='impacts' className='w-full mx-4'>
       <h2 class="w-full text-3xl my-8 font-bold text-center sm:text-4xl md:text-5xl">Impacts Of DPEEIK To
       The Community.</h2>
       <div class="flex flex-col mb-8 animated fadeIn sm:flex-row mx-4">
            
           <div class="flex items-center mb-8 sm:w-1/2 md:w-5/12 sm:order-last">
               <img class="rounded-lg shadow-xl" src={Impact1} alt=""/>
               </div>
           <div class="flex flex-col justify-center mt-5 mb-8 md:mt-0 sm:w-1/2 md:w-7/12 sm:pr-16">
               <p class="mb-2 text-sm font-semibold leading-none text-left text-indigo-600 uppercase">Empowering Lives</p>
               <h3 class="mt-2 text-xl sm:text-left md:text-2xl">A Courtesy Visit by WARHF and DPEEIK</h3>
               <p class="mt-5 text-lg text-gray-700 text md:text-left">The Director WARHF (Dr. Ann Mwangi) and the patron “The Disabled
People Economic Empowerment Initiative - Kenya (DPEEIK)” (Dr. Joseph
Kweri), with people living with disabilities (PLWD) at Ruiru sub-county
offices on a courtesy call.</p>
           </div>
       </div>
       <div class="flex flex-col mb-8 animated fadeIn sm:flex-row mx-4">
       <div class="flex items-center mb-8 sm:w-1/2 md:w-5/12">
               <img class="rounded-lg shadow-xl" src={Impact2} alt=""/>
               </div>
           <div class="flex flex-col justify-center mt-5 mb-8 md:mt-0 sm:w-1/2 md:w-7/12 sm:pl-16">
               <p class="mb-2 text-sm font-semibold leading-none text-left text-indigo-600 uppercase">Strengthening Futures</p>
               <h3 class="mt-2 text-xl sm:text-left md:text-2xl">WARHF and DPEEIK Support Economic Empowerment for PLWDs</h3>
               <p class="mt-5 text-lg text-gray-700 text md:text-left">The Director WARHF (Dr. Ann Mwangi) and the patron “The Disabled
People Economic Empowerment Initiative - Kenya (DPEEIK)” (Dr. Joseph
Kweri), with one of the beneficiary of the stalls constructed through
contributions by JKUAT students in enhancement of economic empowerment
to the (PLWDs)</p>
           </div>
       </div>
       </div>



   </div>
</section>
       </div>
       
       <div className="flex flex-wrap -mx-3 my-5">
         <div className="w-full max-w-full sm:w-3/4 mx-auto text-center">
           <p className="text-sm text-slate-500 py-1">
             DPEEIK is an initiative under <a href="https://warhf.org/" className="text-slate-700 hover:text-blue-600" target="_blank" rel="noopener noreferrer">WARHF</a>. All rights reserved
           </p>
         </div>
       </div>
     </div>
        </>

    );
  };

export default Home;
